import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import * as React from 'react';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import Popper from '@mui/material/Popper';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import isEqual from 'lodash/isEqual';
import TextField from '@mui/material/TextField';
import { validators } from '../../../../_helpers/validationsForm';
import FormTextFieldInput from '../../ShippingIntegration/composents/input/FormTextFieldInput';
import AlloExpressStyle from './AlloExpressStyle';
import CurrierArriveModal from './CurrierArriveModal';
import { formatPhoneNumber } from '../../ShippingIntegration/_helpers/formHelper';
import { getDateWithoutHours } from '../../../../_helpers/getDate';
import { COLOR } from '../../../containers/App/AppStyles';
import NumberFormatWeight from '../../../components/NumberFormat/NumberFormatWeight';
import NumberFormatCustom from '../../../components/NumberFormat/NumberFormatCustom';

const MAX_NUMBER_LENGTH = 7;
const MAX_FORMATED_LENGTH = 10;
const SEPARATOR = '.';
const FOUR_THOUSAND = 4000;
const THIRTY = 30;

const NumberFormat = inputComponentProps => (
  <NumberFormatCustom
    {...inputComponentProps}
    decimalSeparator="."
    thousandSeparator=""
    decimalScale={2}
    allowNegative={false}
    isAllowed={({ formattedValue }) => {
      const lastComa = formattedValue.length === MAX_NUMBER_LENGTH && formattedValue.endsWith(SEPARATOR);
      return !(formattedValue.startsWith(SEPARATOR) || formattedValue.length > MAX_FORMATED_LENGTH || lastComa);
    }}
  />
);

const RADIX_PRECISION = 10;
const MAX_LENGTH = 120;
const MAX_WIDTH = 70;
const MAX_HEIGHT = 70;
const MAX_WEIGHT = 30;
const showCurrie = false;

const StyledPopper = styled(Popper)({
  '& .MuiAutocomplete-listbox': {
    fontSize: '14px',
  },
});

export default function TTNForm({
  formValues, setFormValues, alloExpressInfo, orderAmount, setFormValid, setHasChanges, initialValues,
}) {
  const alloExpressStyles = AlloExpressStyle;
  const [currierArriveModalOpen, setCurrierArriveModalOpen] = useState(false);
  const [errors, setErrors] = useState({ packageOptions: [] });

  const {
    cityAvailableForCurrier, sender, warehouses,
  } = alloExpressInfo;

  const uniqueCities = Array.from(new Set(warehouses.map(item => item.city)));
  const filteredWarehouses = warehouses.filter(warehouse => warehouse.city === formValues.ownerDeliveryCity).map(element => element.address);
  const validateDeliveryTypeFields = (name, value) => {
    let errorMessage = '';
    if (name === 'currierDeliveryCity') {
      errorMessage = (value !== '') ? '' : 'Вкажіть населений пункт';
    } else if (name === 'deliveryStreet') {
      errorMessage = (value !== '') ? '' : 'Вкажіть вулицю';
    } else if (name === 'deliveryHouse') {
      errorMessage = (value !== '') ? '' : 'Вкажіть будинок';
    } else if (name === 'amount') {
      errorMessage = (value !== '') ? '' : 'Вкажіть оціночну вартість';
      if (value !== '' && parseInt(value, RADIX_PRECISION) > orderAmount) {
        errorMessage = 'Оціночна вартість не може бути більше, ніж вартість замовлення';
      }
    } else if (name === 'postPaid') {
      if (value !== '' && parseInt(value, RADIX_PRECISION) > orderAmount) {
        errorMessage = 'Сума післяплати не може бути більше суми замовлення';
      }
    } else {
      errorMessage = '';
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };
  const validatePackageOptions = (index, name, value) => {
    let errorMessage = '';
    if (name === 'length') {
      errorMessage = (value !== '') ? '' : 'Вкажіть довжину';
      if (value !== '' && parseInt(value, RADIX_PRECISION) > MAX_LENGTH) {
        errorMessage = 'Максимальна довжина 120 см';
      }
    } else if (name === 'width') {
      errorMessage = (value !== '') ? '' : 'Вкажіть ширину';
      if (value !== '' && parseInt(value, RADIX_PRECISION) > MAX_WIDTH) {
        errorMessage = 'Максимальна ширина 70 см';
      }
    } else if (name === 'height') {
      errorMessage = (value !== '') ? '' : 'Вкажіть висоту';
      if (value !== '' && parseInt(value, RADIX_PRECISION) > MAX_HEIGHT) {
        errorMessage = 'Максимальна висота 70 см';
      }
    } else if (name === 'weight') {
      errorMessage = (value !== '') ? '' : 'Вкажіть вагу';
      if (value !== '' && parseInt(value, RADIX_PRECISION) > MAX_WEIGHT) {
        errorMessage = 'Максимальна вага 30 кг';
      }
    } else {
      errorMessage = '';
    }
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (!newErrors.packageOptions[index]) {
        newErrors.packageOptions[index] = {};
      }
      newErrors.packageOptions[index][name] = errorMessage;
      return newErrors;
    });
  };

  const isFormValid = () => {
    let deliveryFields = [];
    let currierFieldValid = false;

    if (formValues.deliveryType === 'owner') {
      deliveryFields = ['ownerDeliveryCity', 'deliveryWarehouse', 'amount'];
      currierFieldValid = true;
    } else {
      deliveryFields = ['currierDeliveryCity', 'deliveryStreet', 'deliveryHouse', 'amount'];
      currierFieldValid = formValues.whenCurrierArrive?.date !== '';
    }
    const isPostAmountValid = !errors.postPaid || errors.postPaid === '';
    const isBulkWeightValid = formValues.packageOptions
      .filter(item => item && item.length)
      .filter(item => ((item.length * item.width * item.height) / FOUR_THOUSAND) > THIRTY);
    const areDeliveryFieldsEmpty = deliveryFields.some(field => !formValues[field] || formValues[field].trim() === '');
    const arePackageOptionsEmpty = formValues.packageOptions.some(option => ['length', 'width', 'height', 'weight'].some(field => !option[field] || option[field] === ''));
    const hasDeliveryErrors = deliveryFields.some(field => !!errors[field]);
    const hasPackageErrors = formValues.packageOptions.some((option, index) => ['length', 'width', 'height', 'weight'].some(field => !!errors.packageOptions[index]?.[field]));
    return !(!currierFieldValid || arePackageOptionsEmpty || areDeliveryFieldsEmpty || hasDeliveryErrors || hasPackageErrors || isBulkWeightValid.length > 0 || !isPostAmountValid);
  };

  const checkForChanges = () => {
    setHasChanges(!isEqual(initialValues, formValues));
  };

  useEffect(() => {
    const valid = isFormValid();
    setFormValid(valid);
    checkForChanges();
  }, [errors, formValues, setFormValid]);

  useEffect(() => {
    if (errors.currierDeliveryCity && errors.currierDeliveryCity !== '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        deliveryStreet: '',
      }));
      setFormValues(prevValues => ({
        ...prevValues,
        deliveryStreet: '',
      }));
    }
  }, [errors.currierDeliveryCity]);

  useEffect(() => {
    if (formValues.deliveryType && formValues.deliveryType === 'owner') {
      setErrors(prevErrors => ({
        ...prevErrors,
        ownerDeliveryCity: '',
        deliveryWarehouse: '',
        currierDeliveryCity: '',
        deliveryStreet: '',
        deliveryHouse: '',
        deliveryApartment: '',
        whenCurrierArrive: '',
      }));
      setFormValues(prevValues => ({
        ...prevValues,
        currierDeliveryCity: '',
        deliveryStreet: '',
        deliveryHouse: '',
        deliveryApartment: '',
        whenCurrierArrive: {
          date: '',
          timeRage: '',
          originDate: '',
        },
      }));
    }

    if (formValues.deliveryType && formValues.deliveryType === 'currier') {
      setErrors(prevErrors => ({
        ...prevErrors,
        ownerDeliveryCity: '',
        deliveryWarehouse: '',
      }));
      setFormValues(prevValues => ({
        ...prevValues,
        ownerDeliveryCity: '',
        deliveryWarehouse: '',
      }));
    }
  }, [formValues.deliveryType]);

  useEffect(() => {
    if (errors.deliveryStreet && errors.deliveryStreet !== '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        deliveryHouse: '',
      }));
      setFormValues(prevValues => ({
        ...prevValues,
        deliveryHouse: '',
      }));
    }
  }, [errors.deliveryStreet]);

  useEffect(() => {
    if (errors.deliveryHouse && errors.deliveryHouse !== '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        whenCurrierArrive: '',
      }));
      setFormValues(prevValues => ({
        ...prevValues,
        whenCurrierArrive: {
          date: '',
          timeRage: '',
          originDate: '',
        },
      }));
    }
  }, [errors.deliveryHouse]);


  useEffect(() => {
    setFormValues(prevValues => ({
      ...prevValues,
      senderName: sender.name,
    }));
  }, [sender]);

  const inputChange = (e) => {
    const { value, name } = e.target;
    if (name === 'ownerDeliveryCity') {
      setFormValues(prevValues => ({
        ...prevValues,
        ownerDeliveryCity: value,
        deliveryWarehouse: ' ',
      }));
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: value,
      }));
    }
    validateDeliveryTypeFields(name, value);
  };

  const handlePackageOptionChange = (index, field, value) => {
    const isWeightField = field === 'weight';
    const regex = isWeightField ? /^(0|[1-9]\d*)(\.\d{0,3})?$/ : /^[1-9]\d*$/;

    if (value === '' || regex.test(value)) {
      const newPackageOptions = formValues.packageOptions.map((option, i) => (
        i === index ? { ...option, [field]: value } : option
      ));
      setFormValues(prevValues => ({
        ...prevValues,
        packageOptions: newPackageOptions,
      }));
      validatePackageOptions(index, field, value);
    }
  };
  const handlePostpaidInputChange = (value) => {
    if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
      setFormValues(prevValues => ({
        ...prevValues,
        postPaid: parseFloat(value),
      }));
      validateDeliveryTypeFields('postPaid', value);
    }
  };
  const handleAmountInputChange = (value) => {
    if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
      setFormValues(prevValues => ({
        ...prevValues,
        amount: value,
      }));
      validateDeliveryTypeFields('amount', value);
    }
  };

  const addPackageOption = () => {
    setFormValues(prevValues => ({
      ...prevValues,
      packageOptions: [...prevValues.packageOptions, {
        length: '', width: '', height: '', weight: '',
      }],
    }));
  };

  const removePackageOption = (index) => {
    const newPackageOptions = formValues.packageOptions.filter((_, i) => i !== index);
    setFormValues(prevValues => ({
      ...prevValues,
      packageOptions: newPackageOptions,
    }));
  };
  const handleCurrierArriveTime = (selectedDate, selectedDateString, selectedTimeRage) => {
    setFormValues(prevValues => ({
      ...prevValues,
      whenCurrierArrive: {
        date: selectedDateString,
        timeRage: selectedTimeRage,
        originDate: getDateWithoutHours(selectedDate),
      },
    }));
  };

  const getBulkWeight = (item) => {
    if (item && item.length) {
      return (item.length * item.width * item.height) / FOUR_THOUSAND;
    }
    return '';
  };
  const currierArriveModalClose = () => {
    setCurrierArriveModalOpen(false);
  };

  const getWhenCurrierArrive = () => `${formValues.whenCurrierArrive.date} ${formValues.whenCurrierArrive.timeRage}`;

  return (
    <>
      <CurrierArriveModal
        open={currierArriveModalOpen}
        onClose={currierArriveModalClose}
        accept={handleCurrierArriveTime}
      />
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
          Відправник
        </Typography>
        <Divider />
        <Grid>
          <Grid item xs={6}>
            <Box paddingTop={'20px'} marginBottom={'20px'}>
              <FormControl
                sx={{
                  width: '100%',
                  fontSize: '14px',
                }}
              >
                <InputLabel sx={{ fontSize: '14px' }}>Відправник</InputLabel>
                <Select
                  size="small"
                  name="senderName"
                  fullWidth
                  disabled
                  label={'Відправник'}
                  placeholder=""
                  value={formValues.senderName}
                  variant="outlined"
                >
                  <MenuItem style={{ fontSize: '14px' }} value={formValues.senderName}>{formValues.senderName}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            >
              <Typography sx={alloExpressStyles.titlesStyle}>Телефон:</Typography>
              <Box width={'30px'} />
              <Typography sx={alloExpressStyles.fieldsStyle}>{formatPhoneNumber(sender.phone)}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
          Спосіб доставки
        </Typography>
        <Divider />
        <Grid paddingTop={'20px'} container spacing={4}>
          {showCurrie && (
            <Grid item xs={12}>
              <Box marginBottom={'10px'}>
                <FormControl>
                  <FormLabel
                    sx={{
                      fontSize: '14px',
                      color: '#303030 !important',
                    }}
                    id="allo-express-delivery-type"
                  >Як бажаєте доставити посилку в точку прийому?
                  </FormLabel>
                  <RadioGroup
                    row
                    sx={{ marginTop: '20px' }}
                    value={formValues.deliveryType}
                    onChange={inputChange}
                    aria-labelledby="allo-express-delivery-type"
                    name="deliveryType"
                  >
                    <FormControlLabel
                      sx={{ marginRight: '80px' }}
                      value="owner"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontSize: '14px' }}>Віднесу сам</Typography>
                      }
                    />
                    <FormControlLabel
                      value="currier"
                      control={<Radio />}
                      label={
                        <Typography sx={{ fontSize: '14px' }}>Передати посилку кур`єру</Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          )}
          {formValues.deliveryType === 'owner' && (
            <>
              <Grid item xs={6} sm={6} lg={6}>
                <FormControl
                  sx={{
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: COLOR.grey[72],
                    },
                    fontSize: '14px',
                    width: '100%',
                  }}
                >
                  <InputLabel shrink sx={{ fontSize: '14px' }}>Населений пункт</InputLabel>
                  <Select
                    size="small"
                    focused
                    name="ownerDeliveryCity"
                    onChange={e => inputChange(e)}
                    fullWidth
                    label={'Населений пункт'}
                    placeholder=""
                    value={formValues.ownerDeliveryCity}
                    defaultValue={formValues.ownerDeliveryCity}
                    variant="outlined"
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: COLOR.grey[72],
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: COLOR.grey[72],
                      },
                    }}
                  >
                    {uniqueCities && uniqueCities.length > 0 && uniqueCities.map(element => (
                      <MenuItem style={{ fontSize: '14px' }} value={element}>{element}</MenuItem>
                    ))}
                    {errors.ownerDeliveryCity && (
                      <FormHelperText>{errors.ownerDeliveryCity}</FormHelperText>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <FormControl
                  sx={{
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: COLOR.grey[72],
                    },
                    fontSize: '14px',
                    width: '100%',
                  }}
                >
                  <InputLabel shrink sx={{ fontSize: '14px' }}>Склад</InputLabel>
                  <Select
                    focused
                    size="small"
                    name="deliveryWarehouse"
                    onChange={e => inputChange(e)}
                    fullWidth
                    label={'Склад'}
                    placeholder=""
                    value={formValues.deliveryWarehouse}
                    defaultValue={formValues.deliveryWarehouse}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: COLOR.grey[72],
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: COLOR.grey[72],
                      },
                    }}
                  >
                    {filteredWarehouses && filteredWarehouses.length > 0 && filteredWarehouses.map(element => (
                      <MenuItem style={{ fontSize: '14px' }} value={element}>{element}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {formValues.deliveryType === 'currier' && (
            <>
              <Grid item xs={6} sm={6} lg={6}>
                <Autocomplete
                  disablePortal
                  inputValue={formValues.currierDeliveryCity}
                  PopperComponent={props => <StyledPopper {...props} />}
                  options={[...cityAvailableForCurrier]}
                  defaultValue={cityAvailableForCurrier[0]}
                  onInputChange={(event, newInputValue) => {
                    setFormValues(prevValues => ({
                      ...prevValues,
                      currierDeliveryCity: newInputValue,
                    }));
                    validateDeliveryTypeFields('currierDeliveryCity', newInputValue);
                  }}
                  renderInput={params => (
                    <FormTextFieldInput
                      {...params}
                      label="Населений пункт"
                      input={'text'}
                      name="currierDeliveryCity"
                      textColor={''}
                      variant="outlined"
                      meta={''}
                      error={!!errors.currierDeliveryCity}
                      helperText={errors.currierDeliveryCity}
                      value={formValues.currierDeliveryCity}
                      focused
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <FormTextFieldInput
                  input={'text'}
                  name="deliveryStreet"
                  textColor={''}
                  variant="outlined"
                  focused
                  touched
                  error={!!errors.deliveryStreet}
                  helperText={errors.deliveryStreet}
                  value={formValues.deliveryStreet}
                  onChange={inputChange}
                  label={'Вулиця'}
                  fullWidth
                  disabled={formValues.currierDeliveryCity === ''}
                  placeholder={''}
                  required
                  validation
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validate={validators.required}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <FormTextFieldInput
                  input={'text'}
                  name="deliveryHouse"
                  textColor={''}
                  variant="outlined"
                  meta={''}
                  focused
                  touched
                  disabled={formValues.deliveryStreet === ''}
                  error={!!errors.deliveryHouse}
                  helperText={errors.deliveryHouse}
                  value={formValues.deliveryHouse}
                  onChange={inputChange}
                  label={'Будинок'}
                  fullWidth
                  type={'text'}
                  placeholder={''}
                  required
                  validation
                  validate={validators.required}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <FormTextFieldInput
                  input={'text'}
                  name="deliveryApartment"
                  textColor={''}
                  variant="outlined"
                  meta={''}
                  focused
                  touched
                  value={formValues.deliveryApartment}
                  onChange={inputChange}
                  label={'Квартира'}
                  fullWidth
                  type={'text'}
                  placeholder={''}
                  validation
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextFieldInput
                  input={'text'}
                  focused
                  touched
                  name="whenCurrierArrive"
                  variant="outlined"
                  onClick={() => {
                    if (formValues.deliveryHouse !== '') {
                      setCurrierArriveModalOpen(!currierArriveModalOpen);
                    }
                  }}
                  error={!!errors.whenCurrierArrive}
                  helperText={errors.whenCurrierArrive}
                  label={'Коли приїхати курєру'}
                  fullWidth
                  disabled={formValues.deliveryHouse === ''}
                  type={'text'}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validation
                  validate={validators.required}
                  InputProps={{
                    value: getWhenCurrierArrive(),
                    endAdornment: (
                      <>
                        <MoreTimeIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (formValues.deliveryHouse !== '') {
                              setCurrierArriveModalOpen(!currierArriveModalOpen);
                            }
                          }}
                          color="inherit"
                          size={20}
                        />
                      </>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
          Параметри посилки
        </Typography>
        <Divider />
        <Grid sx={{ mt: '20px' }} container spacing={2}>
          <Grid item xs={12}>
            {formValues.packageOptions.map((option, index) => (
              <Box key={index} mb={2}>
                <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
                  {index + 1} Місце
                </Typography>
                <Grid container sx={{ marginTop: '5px' }} spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Box sx={{ padding: '3px' }}>
                        <FormTextFieldInput
                          input={'text'}
                          name={`packageOptions.${index}.length`}
                          label="Довжина"
                          fullWidth
                          variant="outlined"
                          value={option.length}
                          onChange={e => handlePackageOptionChange(index, 'length', e.target.value)}
                          error={!!errors.packageOptions[index]?.length}
                          helperText={errors.packageOptions[index]?.length}
                          required
                          validation
                          validate={validators.required}
                          InputProps={{
                            maxLength: 10,
                            endAdornment: <InputAdornment position="end">см</InputAdornment>,
                          }}
                        />
                      </Box>
                      <Box sx={{ padding: '3px' }}>
                        <FormTextFieldInput
                          input={'text'}
                          name={`packageOptions.${index}.width`}
                          label="Ширина"
                          fullWidth
                          variant="outlined"
                          value={option.width}
                          onChange={e => handlePackageOptionChange(index, 'width', e.target.value)}
                          error={!!errors.packageOptions[index]?.width}
                          helperText={errors.packageOptions[index]?.width}
                          required
                          validation
                          validate={validators.required}
                          InputProps={{
                            maxLength: 10,
                            endAdornment: <InputAdornment position="end">см</InputAdornment>,
                          }}
                        />
                      </Box>
                      <Box sx={{ padding: '3px' }}>
                        <FormTextFieldInput
                          input={'text'}
                          name={`packageOptions.${index}.height`}
                          label="Висота"
                          fullWidth
                          variant="outlined"
                          value={option.height}
                          onChange={e => handlePackageOptionChange(index, 'height', e.target.value)}
                          error={!!errors.packageOptions[index]?.height}
                          helperText={errors.packageOptions[index]?.height}
                          required
                          validation
                          validate={validators.required}
                          InputProps={{
                            maxLength: 10,
                            endAdornment: <InputAdornment position="end">см</InputAdornment>,
                          }}
                        />
                      </Box>
                      <Box sx={{ padding: '3px' }}>
                        <FormTextFieldInput
                          input={'text'}
                          name={`packageOptions.${index}.weight`}
                          label="Вага"
                          fullWidth
                          variant="outlined"
                          value={option.weight}
                          onChange={e => handlePackageOptionChange(index, 'weight', e.value)}
                          error={!!errors.packageOptions[index]?.weight}
                          helperText={errors.packageOptions[index]?.weight}
                          required
                          validation
                          InputProps={{
                            inputComponent: NumberFormatWeight,
                            maxLength: 10,
                            endAdornment: <InputAdornment position="end">кг</InputAdornment>,
                          }}
                          validate={validators.required}
                        />
                      </Box>
                      <Box sx={{ width: '35px' }}>
                        {((formValues.packageOptions.length > 1 && index !== 0)) && (
                        <Box
                          sx={{
                            width: '32px',
                            height: '32px',
                            marginTop: '5px',
                            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
                          }}
                        >
                          <Button
                            style={{
                              padding: 0,
                              width: '100%',
                              height: '100%',
                              opacity: '0px',
                              background: 'rgba(48, 48, 48, 0.08)',
                              border: 'solid 4px white',
                            }}
                            onClick={() => {
                              removePackageOption(index);
                            }}
                            disabled={formValues.packageOptions.length === 1}
                          >
                            <DeleteOutlinedIcon color={'red'} />
                          </Button>
                        </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      display: 'flex', justifyContent: 'start', width: '100%', color: 'rgba(48, 48, 48, 0.72)',
                    }}
                    >
                      <Tooltip
                        key={index}
                        PopperProps={{ style: { zIndex: 10000 } }}
                        title="Об'ємна вага - це розрахункова величина. Використовується для обчислення вартості доставки за розмірами посилання."
                        placement="right"
                      >
                        <InfoOutlinedIcon fontSize={'14px'} sx={{ cursor: 'pointer', marginRight: '5px' }} />
                      </Tooltip>
                      <Typography fontWeight={400} fontSize={'12px'}>
                        Об`ємна вага: {getBulkWeight(formValues.packageOptions[index])} кг
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    { parseInt(getBulkWeight(formValues.packageOptions[index]), 10) > 30 && (
                      <Typography color={COLOR.error} fontWeight={400} fontSize={'12px'}>
                        {'На жаль, посилки з об`ємною вагою > 30 кг не приймаються до відправлення.'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12}>
            { formValues.packageOptions.length < 20 && (
              <Button
                size={'small'}
                startIcon={<AddCircleOutlineIcon />}
                color={'secondary'}
                onClick={addPackageOption}
                variant="outlined"
              >Додати ще місце
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
          Оціночна вартість
        </Typography>
        <Divider />
        <Grid sx={{ marginTop: '10px' }} container spacing={3}>
          <Grid item xs={12}>
            <Typography color={'#303030'} fontWeight={400} fontSize={14} gutterBottom>
              Оціночна вартість - це вартість відправленої посилки для цілей обліку та страхування.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormTextFieldInput
              input={'text'}
              name={'amount'}
              label="Оціночна вартість"
              fullWidth
              variant="outlined"
              value={formValues.amount}
              onChange={e => handleAmountInputChange(e.value)}
              error={!!errors.amount}
              helperText={errors.amount}
              required
              validation
              InputProps={{
                inputComponent: NumberFormat,
                maxLength: 10,
                endAdornment: <InputAdornment position="end">₴</InputAdornment>,
              }}
              validate={validators.required}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
          Післяплата
        </Typography>
        <Divider />
        <Grid sx={{ marginTop: '10px' }} container spacing={3}>
          <Grid item xs={12}>
            <Typography color={'#303030'} fontWeight={400} fontSize={14} gutterBottom>
              Спосіб оплати, при якому покупець сплачує вартість посилки безпосередньо під час її отримання.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormTextFieldInput
              input={'text'}
              name={'postPaid'}
              label="Сумма"
              fullWidth
              focused
              touched
              variant="outlined"
              value={formValues.postPaid}
              onChange={e => handlePostpaidInputChange(e.value)}
              error={!!errors.postPaid}
              helperText={errors.postPaid}
              placeholder={'00.00'}
              InputProps={{
                inputComponent: NumberFormat,
                maxLength: 10,
                endAdornment: <InputAdornment position="end">₴</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        border={'1px solid rgba(48, 48, 48, 0.08)'}
        padding={'20px'}
        marginBottom={'10px'}
      >
        <Typography color={'#303030'} fontWeight={700} fontSize={14} gutterBottom>
          Примітка
        </Typography>
        <Divider />
        <Grid sx={{ marginTop: '10px' }} container spacing={3}>
          <Grid item xs={12}>
            <Typography color={'#303030'} fontWeight={400} fontSize={14} gutterBottom>
              Текстовий коментар до відправлення
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={{
                fontSize: '14px',
                textarea: {
                  resize: 'both',
                },
                '& label.Mui-focused': {
                  color: '#A0AAB4',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#E0E3E7',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgba(111,126,140,0.24)',
                    borderWidth: '1px',
                  },
                },
              }}
              variant="outlined"
              focused
              name={'comment'}
              multiline
              onChange={inputChange}
              fullWidth
              inputProps={{ maxLength: 30 }}
              value={formValues.comment}
              label="Коментар"
            />
            <Typography variant="body2" color={COLOR.black[38]} align="right">
              {(formValues.comment && formValues.comment.length ? formValues.comment.length : '0')}/30 символів
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
