const AlloExpressStyle = {
  titlesStyle: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    color: 'rgba(48, 48, 48, 0.72)',
  },
  fieldsStyle: {
    fontSize: '14px',
    color: '#303030',
    textAlign: 'end',
  },
  boxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    borderTopLeftRadius: '6px',
  },
};

export default AlloExpressStyle;
