// Core
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  Field, Form, reduxForm, submit,
} from 'redux-form/immutable';
// Icons
import Box from '@mui/material/Box';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
// Parts
import SaveIcons from '@mui/icons-material/Save';
import PenMenuSideBarOutlinedIcon from '../../../../../../icons/PenMenuSideBarOutlinedIcon';
import ModalConfirm from '../../../../../../containers/Modals/ModalConfirm';
import ProductStatusField from './ProductStatusField';
import ButtonWithIconAndCircularProgress from '../../../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import FormSelect from '../../../../../../components/_Form/Selects/FormSelect/FormSelect';
import NotificationText from '../../../../../../components/Notifications/NotificationText';
import ProductManagerField from './ProductManagerField';
import ProductQuantityField from './ProductQuantityField';
import ProductCategoryField from './ProductCategoryField';
import ProductEnabledInMagentoField from './ProductEnabledInMagentoField';
import ProductPriceField from './ProductPriceField';
import ProductFeatureAutoField from './ProductFeatureAuto/ProductFeatureAutoField';
import ProductFeatureAutoModal from './ProductFeatureAuto/ProductFeatureAutoModal';
import ProductPriceAutoField from './ProductPriceAuto/ProductPriceAutoField';
import ProductPriceAutoModal from './ProductPriceAuto/ProductPriceAutoModal';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../../engine/config/access';
import { asyncActions } from '../../../../../../../engine/core/content/saga/asyncActions';
import { userRoles } from '../../../../../../../engine/config/userRoles';
import { useGuideTourRef } from '../../../../../../containers/Context/refContext';
// Helpers
import { useAccessList } from '../../../../../../hooks/useAccessList';
import { validators } from '../../../../../../../_helpers/validationsForm';
import { useUserSomeRole } from '../../../../../../hooks/useUserSomeRole';
import { COLOR, COMPONENTS_TYPES, BUTTON_SIZE } from '../../../../../../containers/App/AppStyles';

export const formName = 'ProductMultipleEditingForm';
export const formFields = Object.freeze({
  property: 'property',
  category: 'category',
  manager: 'manager',
  productStatus: 'productStatus',
  cancelStatus: 'cancelStatus',
  note: 'note',
  quantity: 'quantity',
  priceAutoUpdate: 'priceAutoUpdate',
  featureAutoUpdate: 'featureAutoUpdate',
  enabledInMagento: 'enabledInMagento',
  checkProductsInMagento: 'checkProductsInMagento',
  price: 'price',
  salePrice: 'salePrice',
  updateProductsFromMagento: 'updateProductsFromMagento',
  updateProductsMediaFromMagento: 'updateProductsMediaFromMagento',
  updateProductsInElastic: 'updateProductsInElastic',
});

const propertyWithFields = [
  formFields.manager,
  formFields.featureAutoUpdate,
  formFields.priceAutoUpdate,
  formFields.productStatus,
  formFields.quantity,
  formFields.category,
  formFields.enabledInMagento,
  formFields.salePrice,
  formFields.price,
];

function ProductMultipleEditingForm(props) {
  const { handleSubmit, valid } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const [label, setLabel] = useState('');
  const [showFeatureAutoModal, setShowFeatureAutoModal] = useState(false);
  const [showPriceAutoModal, setShowPriceAutoModal] = useState(false);
  const [forceActionMessage, setForceActionMessage] = useState(undefined);
  const hasAccessToCheckInMagento = useUserSomeRole([userRoles.admin]);
  const hasAccessToEnabledInMagento = useUserSomeRole([userRoles.admin, userRoles.contentManager]);
  const hasAccessToProductStatusEdit = useAccessList(accessList.productStatusEdit);
  const hasPartnerManagerEditAccess = useAccessList(accessList.partnerManagerEdit);
  const hasProductPricesImportAccess = useAccessList(accessList.productsImportPrices);
  const hasUpdateProductsFromMagentoAccess = useAccessList(accessList.updateProductsFromMagento);
  const hasCheckProductsMidInMagentoAccess = useAccessList(accessList.checkProductsMidInMagento);
  const hasProductChangeCategoryAccess = useAccessList(accessList.productChangeCategory);
  const hasProductChangeQuantityAccess = useAccessList(accessList.productChangeQuantity);
  const hasProductsPriceAutoUpdateBulkEdit = useAccessList(accessList.productsPriceAutoUpdateBulkEdit);
  const hasProductsFeatureAutoUpdateBulkEdit = useAccessList(accessList.productsFeatureAutoUpdateBulkEdit);
  const hasUpdateProductsInElastic = useAccessList(accessList.updateProductsInElastic);
  const getFormValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const productEditPending = useSelector(selectors.allProductsTable.productEditPending);
  const selections = useSelector(selectors.contentProduct.selection);
  const filters = useSelector(selectors.contentProduct.filters);
  const isEditSelectOpen = useSelector(selectors.ui.isSelectOpen).toJS().edit;
  const property = getFormValues.get(formFields.property);
  const guideTourRef = useGuideTourRef();

  useEffect(() => {
    if (selections.size) {
      setLabel('Редактировать выбранное');
    } else if (filters.size) {
      setLabel('Редактировать фильтрованное');
    } else {
      setLabel('Редактировать все');
    }
  }, [selections.size, filters.size]);

  const options = useMemo(
    () => {
      const optionsValues = [];
      if (hasProductChangeQuantityAccess) {
        optionsValues.push({
          value: formFields.quantity,
          label: t('Наличие'),
        });
      }
      if (hasProductChangeCategoryAccess) {
        optionsValues.push({
          value: formFields.category,
          label: t('Категория'),
        });
      }
      if (hasAccessToProductStatusEdit) {
        optionsValues.push({
          value: formFields.productStatus,
          label: t('Статус'),
        });
      }
      if (hasPartnerManagerEditAccess) {
        optionsValues.push({
          value: formFields.manager,
          label: t('Менеджер контента'),
        });
      }
      if (hasAccessToCheckInMagento || hasCheckProductsMidInMagentoAccess) {
        optionsValues.push({
          value: formFields.checkProductsInMagento,
          label: t('Проверка M-ID товаров'),
        });
      }
      if (hasAccessToEnabledInMagento) {
        optionsValues.push({
          value: formFields.enabledInMagento,
          label: t('Статус товара на сайте'),
        });
      }
      if (hasProductPricesImportAccess) {
        optionsValues.push({
          value: formFields.price,
          label: t('Стоимость'),
        });
        optionsValues.push({
          value: formFields.salePrice,
          label: t('Акционная стоимость'),
        });
      }
      if (hasUpdateProductsFromMagentoAccess) {
        optionsValues.push({
          value: formFields.updateProductsFromMagento,
          label: t('Обновить данные из MG'),
        });
      }
      if (hasUpdateProductsFromMagentoAccess) {
        optionsValues.push({
          value: formFields.updateProductsMediaFromMagento,
          label: t('Обновить фото из MG'),
        });
      }
      if (hasProductsFeatureAutoUpdateBulkEdit) {
        optionsValues.push({
          value: formFields.featureAutoUpdate,
          label: t('Обновление характеристик'),
        });
      }
      if (hasProductsPriceAutoUpdateBulkEdit) {
        optionsValues.push({
          value: formFields.priceAutoUpdate,
          label: t('Обновление цен и наличия'),
        });
      }
      if (hasUpdateProductsInElastic) {
        optionsValues.push({
          value: formFields.updateProductsInElastic,
          label: t('Переиндексировать'),
        });
      }
      return optionsValues;
    },
    [
      hasProductPricesImportAccess,
      hasAccessToProductStatusEdit,
      hasAccessToEnabledInMagento,
      hasPartnerManagerEditAccess,
      hasProductChangeCategoryAccess,
      hasAccessToCheckInMagento,
      hasUpdateProductsFromMagentoAccess,
      hasProductChangeQuantityAccess,
      hasProductsFeatureAutoUpdateBulkEdit,
      hasProductsPriceAutoUpdateBulkEdit,
      hasUpdateProductsInElastic,
      hasCheckProductsMidInMagentoAccess,
      i18n?.language,
    ],
  );

  const onSubmit = (value) => {
    dispatch(asyncActions.contentProductBulkEditAsync({
      formData: value.toJS(),
      categoryId,
      forceAction: Boolean(forceActionMessage),
      forceCallBack: setForceActionMessage,
    }));
  };
  const handleForceActionCancel = () => {
    setForceActionMessage(undefined);
  };
  const handleForceActionConfirm = () => {
    dispatch(submit(formName));
  };
  const handleSubmitForm = () => {
    switch (property) {
      case formFields.featureAutoUpdate: {
        setShowFeatureAutoModal(true);
        break;
      }
      case formFields.priceAutoUpdate: {
        setShowPriceAutoModal(true);
        break;
      }
      default: {
        dispatch(submit(formName));
      }
    }
  };

  return (
    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        color: '#fff',
      }}
      >
        <PenMenuSideBarOutlinedIcon sx={{ marginRight: '10px' }} />
        <Box sx={{ fontSize: 16, fontWeight: '700' }}>
          <Trans>{label}</Trans>
        </Box>
      </Box>
      <Form onSubmit={handleSubmit(onSubmit)} id="multiple_form_edit">
        <Field
          component={FormSelect}
          isPortal
          name={formFields.property}
          hasSearch={false}
          fullWidth
          selectFirst={options.length === 1}
          variant="outlined"
          options={options}
          label={t('Изменить')}
          validation
          validate={[validators.required]}
          color={COMPONENTS_TYPES.SELECT.dark}
          isSelectOpen={isEditSelectOpen}
          ref={guideTourRef}
        />
        {propertyWithFields.includes(property) && (
          <Box sx={{ marginTop: '24px' }}>
            {property === formFields.manager && <ProductManagerField isPortal={false} color={COMPONENTS_TYPES.SELECT.dark} validate={[validators.required]} />}
            {property === formFields.productStatus && <ProductStatusField />}
            {property === formFields.quantity && <ProductQuantityField />}
            {property === formFields.category && <ProductCategoryField />}
            {property === formFields.enabledInMagento && <ProductEnabledInMagentoField />}
            {property === formFields.featureAutoUpdate && <ProductFeatureAutoField />}
            {property === formFields.priceAutoUpdate && <ProductPriceAutoField />}
            {(property === formFields.salePrice || property === formFields.price)
              && (
                <ProductPriceField
                  name={property}
                  label={property === formFields.salePrice ? t('Акционная стоимость') : t('Стоимость')}
                />
              )
            }
          </Box>
        )}
        <ButtonWithIconAndCircularProgress
          fullWidth
          darkTheme
          text={t('Применить')}
          isLoading={productEditPending}
          type="button"
          onClick={handleSubmitForm}
          color="primary"
          component="button"
          sx={{ margin: '28px auto', p: BUTTON_SIZE.outlinedSmall }}
          disabled={!valid}
          id="apply_multiple_edit_form"
        >
          <SaveIcons />
        </ButtonWithIconAndCircularProgress>
      </Form>
      <ModalConfirm
        confirmText={t('Подтвердить')}
        textAlign="center"
        pendingConfirm={productEditPending}
        onCancel={handleForceActionCancel}
        onConfirm={handleForceActionConfirm}
        showModal={Boolean(forceActionMessage)}
        text={forceActionMessage}
        title={t('Внимание')}
      />

      {hasProductsFeatureAutoUpdateBulkEdit && (
        <ProductFeatureAutoModal
          showModal={showFeatureAutoModal}
          setShowModal={setShowFeatureAutoModal}
        />
      )}
      {hasProductsPriceAutoUpdateBulkEdit && (
        <ProductPriceAutoModal
          showModal={showPriceAutoModal}
          setShowModal={setShowPriceAutoModal}
        />
      )}
      {(
        property === formFields.quantity
        || property === formFields.salePrice
        || property === formFields.price
      ) && (
        <NotificationText
          icon={<FeedbackOutlinedIcon sx={{ color: COLOR.darkStyle.brand.main }} />}
          title={t('Внимание!')}
          titleColor={COLOR.darkStyle.brand.main}
          border={`1px solid ${COLOR.darkStyle.brand.main}`}
          backgroundColor="rgba(249, 121, 136, 0.3)"
          boxShadow="none"
          textColor={COLOR.darkStyle.fontColorMain}
        >
          {t("Если среди изменяемых товаров, есть товары в автоматическом режиме управления ценой/наличием - при изменении 'Стоимости'/'Акционной стоимости'/'Наличия', они будут переведены в ручной режим управления ценой/наличием.")}
        </NotificationText>
      )}
    </>
  );
}

export default reduxForm({
  form: formName,
})(ProductMultipleEditingForm);
